import * as React from 'react';
import CompareObject from "./CompareObject";
import styled from "@emotion/styled";


const StyledCompare = styled.div`
  width: 100%;
  display: flex;
  
  /* Small Devices, Tablets */
  @media only screen and (min-width : 350px) {
    flex-direction: column;
  }

  /* Medium Devices, Desktops */
  @media only screen and (min-width : 750px) {
    flex-direction: revert;
  }
`

const StyledCompareItem = styled.div`
  & h1 {
    text-align: center;
    margin: 10px 20px;
  }
`

export default function Compare({value}) {
    const startValue = value.current.label
    const startCode = value.current.code
    const endValue = value.destination.label
    const endCode = value.destination.code
    const sameCountryHold = sameCountryTags[Math.floor(Math.random() * sameCountryTags.length)]

    // Function to retrieve object based on matching values
    function getObjectByValue(array, key, value) {
        for (const obj of array) {
            if (obj[key] === value) {
                return obj; // Return the matching object
            }
        }
        return null; // Return null if no match is found
    }

    const startObject = getObjectByValue(rules, 'label', startValue);
    const endObject = getObjectByValue(rules, 'label', endValue);


    //TODO does the final object need to be unique? NO
    // const finalStartObject = removeCommonElements(startObject, endObject);
    // const finalEndObject = removeCommonElements(endObject, startObject);
    const finalStartObject = startObject;
    const finalEndObject = endObject;

    if (startValue === endValue) {
        return (
            <div>
                {sameCountryHold}
            </div>
        )
    } else {
        return (
            <StyledCompare>
                <StyledCompareItem>
                    <h1>
                        <img
                            loading="lazy"
                            width="32"
                            height="24"
                            srcSet={
                                `https://flagcdn.com/64x48/${startCode.toLowerCase()}.png 2x, 
                                        https://flagcdn.com/96x72/${startCode.toLowerCase()}.png 3x`
                            }
                            src={`https://flagcdn.com/32x24/${startCode.toLowerCase()}.png`}
                            alt=""
                        /> &nbsp;
                        {startValue}
                    </h1>
                    <CompareObject value={finalStartObject}/>
                </StyledCompareItem>
                <StyledCompareItem>
                    <h1>
                        <img
                            loading="lazy"
                            width="32"
                            height="24"
                            srcSet={
                                `https://flagcdn.com/64x48/${endCode.toLowerCase()}.png 2x, 
                                        https://flagcdn.com/96x72/${endCode.toLowerCase()}.png 3x`
                            }
                            src={`https://flagcdn.com/32x24/${endCode.toLowerCase()}.png`}
                            alt=""
                        /> &nbsp;
                        {endValue}
                    </h1>
                    <CompareObject value={finalEndObject}/>
                </StyledCompareItem>
            </StyledCompare>
        );
    }
}

const sameCountryTags = [
    "Going nowhere fast, I see!",
    "A true master of the staycation.",
    "You've unlocked the 'Home Sweet Home' achievement.",
    "You're on a one-way trip to... exactly where you are!",
    "Spicing up your daily commute, are we?",
    "Who needs a passport when you've got your backyard?",
    "Exploring the uncharted territory of your own neighborhood."
]

// Removed: speed limit, merge, parking payment, enforcement penalties, alcohol transportation, passanger laws
const rules = [
    {
        label: 'United States',
        driveSide: 'Right',
        governmentSite: 'https://travel.state.gov/content/travel.html',
        ageRequirement: [
            'State dependant',
            'https://blog.rentcars.com/en/how-old-to-rent-a-car'
        ],
        rightOfWay: 'https://www.firsttimedriver.com/blog/determining-who-has-right-of-way/',
        currentRoadConditions: 'https://www.safetravelusa.com/',
        signs: 'https://mutcd.fhwa.dot.gov/services/publications/fhwaop02084/index.htm',
        signal: [
            '3',
            'https://en.wikipedia.org/wiki/Variations_in_traffic_light_operation#Between_red_and_green'
        ],
        requiresIDP: [
            'State dependant IDP requirements',
            'https://www.usa.gov/non-citizen-driving'
        ],
        localEmergencyNumber: '911',
        alcoholLimit: '0.08% Blood Alcohol Concentration (BAC)',
        vehicleRequirement: 'State dependant',
        genderRules: '',
        currentWeather: 'https://www.weather.gov/',
        fuelTypes: 'Gas (Black), Diesel (Green), Biofuel/Ethanol/E85 (Yellow)',
        localHazards: [
            'Every hazard but Tsunamis',
            'https://www.usgs.gov/science/science-explorer/natural-hazards'
        ],
        expectedGasPrice: .83,
        notes: [
            'Each state will have unique traffic laws, such as cell phone usage restrictions',
            'Right on red is allowed',
            [
                'Weird Traffic Laws By State',
                'https://blog.nationwide.com/vehicle/vehicle-safety/weird-traffic-laws-by-state/'
            ]
        ]
    },
    {
        label: 'Iceland',
        driveSide: 'Right',
        governmentSite: 'https://www.government.is/',
        ageRequirement: [
            'Minimum 20 for any, minimum 23 for 4x4',
            'https://www.hertz.is/rental-requirements/'
        ],
        rightOfWay: 'https://www.northbound.is/blog/283/icelandic-traffic-laws-everything-you-need-to-know',
        currentRoadConditions: 'https://umferdin.is/en',
        signs: 'https://en.wikipedia.org/wiki/Road_signs_in_Iceland',
        signal: [
            '4',
            'https://en.wikipedia.org/wiki/Variations_in_traffic_light_operation#Between_red_and_green'
        ],
        requiresIDP: [
            'Country dependant International Driving Permit requirement',
            'https://www.carsiceland.com/post/iceland-driving-license'
        ],
        usEmbassyLink: 'https://is.usembassy.gov/u-s-citizen-services/local-resources-of-u-s-citizens/emergency-assistance/',
        localEmergencyNumber: '112',
        alcoholLimit: '0.02% Blood Alcohol Concentration (BAC)',
        vehicleRequirement: 'Highly recommended 4x4',
        genderRules: '',
        currentWeather: 'https://www.yr.no/en/map/weather/2-3413829/Iceland/Capital%20Region/Reykjav%C3%ADkurborg/Reykjavik',
        fuelTypes: 'Bensín/Petrol/Gas (Green), Dísil/Diesel (Black)',
        localHazards: [
            'Storms, floods, earthquakes, volcanic eruptions, landslides and avalanches',
            'https://www.government.is/topics/public-safety-and-security/meteorology-and-natural-hazards'
        ],
        expectedGasPrice: 2.12,
        notes: [
            'Inner lane of a two-lane roundabout always has priority over traffic on the outer lane',
            'Car headlights must be on at all times when driving',
            'Winter tires for certain months of the year',
            'Off-road driving is illegal',
            'All passengers must wear seatbelt',
            'Using a cell phone when driving is illegal',
            [
                'Northern Lights App (Apple Store)',
                'https://apps.apple.com/us/app/my-aurora-forecast-alerts/id1073082439'
            ],
            [
                'Northern Lights App (Google Store)',
                'https://play.google.com/store/apps/details?id=com.jrustonapps.myauroraforecast&hl=en_US&gl=US'
            ]
        ]
    },
    {
        label: 'New Zealand',
        driveSide: 'Left',
        governmentSite: 'https://www.govt.nz/',
        ageRequirement: [
            'Minimum 18 and held full licence for 12 months',
            'https://wikitravel.org/en/Buying_or_renting_a_car_in_New_Zealand'
        ],
        rightOfWay: 'https://www.nzta.govt.nz/roadcode/general-road-code/about-driving/giving-way/the-give-way-rules',
        currentRoadConditions: 'https://www.journeys.nzta.govt.nz/highway-conditions',
        signs: 'https://en.wikipedia.org/wiki/Road_signs_in_New_Zealand',
        signal: [
            '3',
            'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*t03qBGv_S_Iz7dxnOnwt_w.png'
        ],
        requiresIDP: [
            'International Driving Permit requirement',
            'https://www.nzta.govt.nz/driver-licences/new-residents-and-visitors/driving-on-nz-roads/'
        ],
        usEmbassyLink: 'https://nz.usembassy.gov/',
        localEmergencyNumber: '111',
        alcoholLimit: '0.05% Blood Alcohol Concentration (BAC)',
        vehicleRequirement: 'None',
        genderRules: '',
        currentWeather: 'https://www.yr.no/en/map/weather/2-2193733/New%20Zealand/Auckland/Auckland/Auckland',
        fuelTypes: 'Petrol/Gas (Green 91, Red 95), Dísil/Diesel (Black)',
        localHazards: [
            'Earthquakes, flooding, volcanoes, tsunami, wildfires, landslides',
            'https://niwa.co.nz/natural-hazards/hazards'
        ],
        expectedGasPrice: 1.7,
        notes: [
            'Extra cost to take car over ferry',
            'Wellington to Auckland about 8 hours',
            [
                'Flashing Red',
                'https://en.wikipedia.org/wiki/Variations_in_traffic_light_operation#Flashing_red'
            ],
            [
                'Some road rules',
                'https://www.wisemove.co.nz/post/road-rules-every-driver-should-know-before-they-visit-new-zealand#:~:text=It%20is%20illegal%20to%20use,is%20too%20dangerous%20to%20pass.'
            ]
        ]
    },
    {
        label: 'South Africa',
        driveSide: 'Left',
        governmentSite: 'https://www.gov.za/',
        ageRequirement: [
            'Minimum 18',
            'https://www.hertz.co.za/car-rental-faqs/'
        ],
        rightOfWay: 'https://www.gsafrica.com/travel-guide/driving-in-south-africa/#:~:text=compulsory%20as%20well.-,Who%20has%20right%20of%20way%20when%20driving%20in%20South%20Africa,traffic%20has%20right%20of%20way.',
        currentRoadConditions: 'https://www.i-traffic.co.za/map',
        signs: 'https://en.wikipedia.org/wiki/Road_signs_in_South_Africa',
        signal: [
            '4',
            'https://en.wikipedia.org/wiki/Variations_in_traffic_light_operation#Between_red_and_green'
        ],
        requiresIDP: [
            'International Driving Permit requirement',
            'https://www.idltrip.com/IDL/IDLTopicItem?topicId=136#:~:text=You%20can%20drive%20in%20South,when%20driving%20in%20South%20Africa.'
        ],
        usEmbassyLink: 'https://za.usembassy.gov/',
        localEmergencyNumber: '112',
        alcoholLimit: '0.05% Blood Alcohol Concentration (BAC)',
        vehicleRequirement: 'Sturdy / well built to minimize breaking down',
        genderRules: '',
        currentWeather: 'https://www.yr.no/en/map/weather/2-8335359/Republic%20of%20South%20Africa/Western%20Cape%20Province/West%20Coast%20District%20Municipality/South%20Africa',
        fuelTypes: 'Petrol/Gas (Green), Diesel (Black)',
        localHazards: [
            'Wildfire, earthquake, flood',
            'https://climateknowledgeportal.worldbank.org/country/south-africa/vulnerability'
        ],
        expectedGasPrice: 1.18,
        notes: [
            'Traffic lights are called "Robots" ',
            'Tons of potholes',
            'Local police harassment, know the rules',
            'eSqatini and Lesotho are different countries',
            'Keep doors locked, pay attention to surroundings',
            [
                'Some road rules',
                'https://www.arrivealive.mobi/rules-on-the-road'
            ]
        ]
    },
    {
        label: 'France',
        driveSide: 'Right',
        governmentSite: 'https://www.diplomatie.gouv.fr/en/',
        ageRequirement: [
            'Minimum 21 with at least 1 year with licence',
            'https://www.france.fr/en/holiday-prep/renting-car-and-driving-france'
        ],
        rightOfWay: 'https://www.onisr.securite-routiere.gouv.fr/en/node/497#:~:text=Travelling%20on%20French%20roads&text=At%20crossings%2C%20priority%20is%20to,give%2Dway%20or%20a%20stop.',
        currentRoadConditions: 'https://www.autoroutes.fr/en/Realtime-traffic-information.htm',
        signs: 'https://en.wikipedia.org/wiki/Road_signs_in_France',
        signal: [
            '3',
            'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*t03qBGv_S_Iz7dxnOnwt_w.png'
        ],
        requiresIDP: [
            'International Driving Permit requirement',
            'https://www.eplaque.fr/en/international-driving-permit-france'
        ],
        usEmbassyLink: 'https://fr.usembassy.gov/',
        localEmergencyNumber: '112',
        alcoholLimit: '0.05% Blood Alcohol Concentration (BAC)',
        vehicleRequirement: 'None',
        genderRules: '',
        currentWeather: 'https://www.yr.no/en/map/weather/2-2988507/France/%C3%8Ele-de-France/Paris/Paris',
        fuelTypes: 'Essence/Petrol/Gas (Green), Gazole/Diesel (Yellow)',
        localHazards: [
            'Earthquakes, tsunamis, volcanoes, floods, landslides, avalanches, hurricanes',
            'https://www.oecd-ilibrary.org/sites/354e5924-en/index.html?itemId=/content/component/354e5924-en'
        ],
        expectedGasPrice: 1.84,
        notes: [
            'Rent at train station in a suburb to avoid driving in town',
            'Tons of speed cameras',
            [
                'priorité a droite (right side priority)',
                'https://yourkeytoburgundy.com/travel-planning-tools/driving-in-france/#:~:text=One%20of%20the%20big%20differences,apply%20on%20the%20major%20autoroutes.',
            ],
        ]
    },
    {
        label: 'Italy',
        driveSide: 'Right',
        governmentSite: 'https://www.governo.it/en',
        ageRequirement: [
            'Minimum 18 with at least 1 year with licence',
            'https://www.autoeurope.com/travel-guides/italy/italy-car-rental-faq/#:~:text=In%20order%20to%20rent%20a,pay%20a%20young%20driver\'s%20surcharge.'
        ],
        rightOfWay: 'https://www.insurance4carhire.com/guides/driving-in-italy#:~:text=You%20should%20always%20give%20priority,reverse%20to%20a%20passing%20point.',
        currentRoadConditions: 'https://tripconditions.com/around-Italy',
        signs: 'https://en.wikipedia.org/wiki/Road_signs_in_Italy',
        signal: [
            '3',
            'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*t03qBGv_S_Iz7dxnOnwt_w.png'
        ],
        requiresIDP: [
            'International Driving Permit requirement',
            'https://it.usembassy.gov/u-s-citizen-services/local-resources-of-u-s-citizens/transportation-driving/'
        ],
        usEmbassyLink: 'https://it.usembassy.gov/',
        localEmergencyNumber: '112',
        alcoholLimit: '0.05% Blood Alcohol Concentration (BAC)',
        vehicleRequirement: 'None',
        genderRules: '',
        currentWeather: 'https://www.yr.no/en/map/weather/2-3169070/Italy/Lazio/Province%20of%20Rome/Rome',
        fuelTypes: 'Benzina/Petrol/Gas (Green), Gasolio/Diesel (Black/Blue)',
        localHazards: [
            'Earthquakes, Landslides, Flooding, Volcanoes',
            'https://en.wikipedia.org/wiki/Natural_hazards_in_Italy'
        ],
        expectedGasPrice: 1.86,
        notes: [
            'Avoid driving in big cities',
            'ZTL, restricted zones',
            'Most rentals are stick',
            [
                'Lots of tolls, bring cash',
                'https://www.autostrade.it/en/pedaggio',
            ],
        ]
    },
    {
        label: 'Spain',
        driveSide: 'Right',
        governmentSite: 'https://www.lamoncloa.gob.es/lang/en/paginas/index.aspx',
        ageRequirement: [
            'Minimum 21',
            'https://www.autoeurope.com/travel-guides/spain/spain-car-rental-faq/#:~:text=In%20order%20to%20rent%20a,the%20agreed%20upon%20rental%20rate.'
        ],
        rightOfWay: 'https://www.insurance4carhire.com/guides/driving-in-spain#:~:text=Drivers%20approaching%20an%20intersection%20must,vehicles%20coming%20from%20either%20direction.',
        currentRoadConditions: 'https://infocar.dgt.es/etraffic/',
        signs: 'https://en.wikipedia.org/wiki/Road_signs_in_Spain',
        signal: [
            '3',
            'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*t03qBGv_S_Iz7dxnOnwt_w.png'
        ],
        requiresIDP: [
            'International Driving Permit Required',
            'https://es.usembassy.gov/frequently-asked-questions/'
        ],
        usEmbassyLink: 'https://es.usembassy.gov/',
        localEmergencyNumber: '112',
        alcoholLimit: '0.05% Blood Alcohol Concentration (BAC), 0.03% for less than two years experience',
        vehicleRequirement: 'None',
        genderRules: '',
        currentWeather: 'https://www.yr.no/en/map/weather/2-3128760/Spain/Catalonia/Barcelona/Barcelona',
        fuelTypes: 'Gasolina/Petrol/Gas (Green), Gasoleo/Diesel (Black)',
        localHazards: [
            'Flooding, Landslides, Volcanoes, Earthquakes, Tsunami',
            'https://thinkhazard.org/en/report/229'
        ],
        expectedGasPrice: 1.6,
        notes: [
            'Do not forget your IDP',
            'Plan parking ahead of time',
            'Most rentals are stick',
            'Rain causes locals to forget how to drive',
            [
                'Hitchhiking is only allowed on national roads',
                'https://www.idealista.com/en/news/legal-advice-in-spain/2023/10/31/154053-spains-most-curious-and-absurd-laws#:~:text=Hitchhiking%20is%20forbidden%20on%20Spanish,the%20hitchhiker%20and%20the%20driver.',
            ],
        ]
    },
];