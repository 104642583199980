import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from "@mui/material/Box";
import {Link} from "@mui/material";


export default function Compare({value}) {

    return (
        <div>
            <div>
            <h1>Checklist</h1>
            </div>
            <Box display="flex" justifyContent="center" sx={{color: 'primary.main'}}>
                <FormGroup>
                    <FormControlLabel control={<Checkbox />} label="Driver’s License" />
                    <FormControlLabel control={<Checkbox />} label="International Driving Permits" />
                    <FormControlLabel control={<Checkbox />} label="Insurance " />
                    <FormControlLabel control={<Checkbox />} label="Download offline maps " />
                    <FormControlLabel control={<Checkbox />} label={
                        <Link
                            href="https://www.amazon.com/dp/B07QXV6N1B/?coliid=I1QMRFHIF77K64&colid=1QG5UWY4BUE3P&psc=1&ref_=list_c_wl_lv_ov_lig_dp_it"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                        Portable Charger Power Bank
                        </Link>
                    }/>
                </FormGroup>
            </Box>
        </div>
    );

}
