import styled from '@emotion/styled'
import {theme} from './theme'

const StyledHeader = styled.div`
background-color: ${theme.headerBackground};
position: fixed;
top: 0;
left: 0;
width: 100%;
color: ${theme.primary};
box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px 20px;
z-index: 999;

/* Small Devices, Tablets */
@media only screen and (min-width : 350px) {
  justify-content: center;
  height: 8vh;
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 750px) {
  justify-content: space-between;
  height: 7vh;
}
`

export const Header = () => {
    return (
        <StyledHeader>
            <h1>Passport Pedals</h1>
        </StyledHeader>
    )
}
