import * as React from 'react';
import {Grid, ListItem, Box} from "@mui/material";


export default function CompareObject({value}) {

    return (

        <Grid item xs={6} padding={2}>
            <Box sx={{color: 'primary.main'}}>
            {/*<Box>*/}
            <h2>Info</h2>
            {value.driveSide ? <ListItem><b>Driving Side:</b> &nbsp; {value.driveSide}</ListItem> : ""}
            {value.localEmergencyNumber ? <ListItem><b>Emergency Number:</b> &nbsp; {value.localEmergencyNumber}</ListItem> : ""}
            {value.alcoholLimit ? <ListItem><b>Alcohol Limit:</b> &nbsp; {value.alcoholLimit}</ListItem> : ""}
            {value.fuelTypes ? <ListItem><b>Fuel Types:</b> &nbsp; {value.fuelTypes}</ListItem> : ""}
            {value.vehicleRequirement ? <ListItem><b>Vehicle Requirement:</b> &nbsp; {value.vehicleRequirement}</ListItem> : ""}
            {value.ageRequirement ? <ListItem>
                <b>Car Rental Age Requirement:</b> &nbsp;
                <a
                    href={value.ageRequirement[1]}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {value.ageRequirement[0]}
                </a>
            </ListItem> : ""}
            {value.expectedGasPrice ?
                <ListItem>
                    <b>Expected Gas Price:</b> &nbsp; &euro;{value.expectedGasPrice}/liter
                </ListItem> : ""}
            {value.personalScore ? <ListItem><b>Personal Score:</b> &nbsp; {value.personalScore}/10</ListItem> : ""}
            </Box>
            <Box sx={{color: 'primary.main'}}>
                <h2>Links</h2>
                {value.governmentSite ? <ListItem>
                    <a
                        href={value.governmentSite}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Government
                    </a>
                </ListItem> : ""}
                {value.usEmbassyLink ? <ListItem>
                    <a
                        href={value.usEmbassyLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        US Embassy
                    </a>
                </ListItem> : ""}
                {value.currentRoadConditions ? <ListItem>
                    <a
                        href={value.currentRoadConditions}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Current Road Conditions
                    </a>
                    </ListItem> : ""}
                {value.signs ? <ListItem>
                    <a
                        href={value.signs}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Signs
                    </a>
                </ListItem> : ""}
                {value.signal ? <ListItem>
                    <a
                        href={value.signal[1]}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {value.signal[0]} State Traffic Light
                    </a>
                    </ListItem> : ""}
                {value.requiresIDP ? <ListItem>
                    <a
                        href={value.requiresIDP[1]}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {value.requiresIDP[0]}
                    </a>
                </ListItem> : ""}
                {value.rightOfWay ? <ListItem>
                    <a
                        href={value.rightOfWay}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Right Of Way
                    </a>
                </ListItem> : ""}
                    {/*//TODO update format*/}
                {value.genderRules ? <ListItem>Gender Rules: {value.genderRules}</ListItem> : ""}
                {value.currentWeather ? <ListItem>
                    <a
                        href={value.currentWeather}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Current Weather
                    </a>
                </ListItem> : ""}
                {value.localHazards ? <ListItem>
                    <a
                        href={value.localHazards[1]}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {value.localHazards[0]}
                    </a>
                </ListItem> : ""}
            </Box>
            <Box sx={{color: 'primary.main'}}>
            <h2>Notes</h2>
            {value.notes ? <div>
                {value.notes.map((e, i) => (
                    <ListItem key={i}>
                        {Array.isArray(e) ?
                            <a
                                href={e[1]}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {e[0]}
                            </a>
                            : e}
                    </ListItem>
                    ))}
                </div>
                : ""}
            </Box>
        </Grid>
    )
}
