import * as React from 'react';
import {useState} from "react";
import styled from '@emotion/styled';
import Box from "@mui/material/Box";
import Input from './Input';
import Compare from './Compare';
import CheckList from './CheckList';
import {Header} from './Header';
import {theme} from './theme';

const StyledApp = styled.div`
background-color: ${theme.background};
color: ${theme.primary};
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
min-height: 100vh;
min-width: 100vw;
`

function App() {
    const [startValue, setStartValue] = useState({current: null, destination: null});

    function updateCurrentValue(value) {
        setStartValue({current: value, destination: startValue.destination})
    }
    function updateDestinationValue(value) {
        setStartValue({current: startValue.current, destination: value})
    }
    //TODO have header be a button that updates values to empty to reset the compare

  return (
        <StyledApp>
            <Header />
            <br/>
            <Input updateCurrentValue={updateCurrentValue} updateDestinationValue={updateDestinationValue}/>
            <br/>
            {startValue.current && startValue.destination ? <Compare value={startValue}/> : <CheckList />}
            <a
                href="mailto:adrianalvarez.engineer@gmail.com?subject=Passport Pedals Feedback!"
                style={{ color: "#61dafb", padding: "10px 20px" }}
            >
                E-Mail feedback
            </a>
            <Box display="flex" justifyContent="center" sx={{color: 'secondary.main'}}>
                <p><b>Disclaimer:</b> This is a jump off point to help <i>start</i> planning your travels.</p>
            </Box>
        </StyledApp>
  );
}

export default App;
